import React, { useState } from "react";
import { Cart } from "@/utils/cart";
import { useDispatch } from "react-redux";
import api from "@/api/api";
import { removeItem, updateCount } from "@/state/cart/cartSlice";
import { useAppSelector } from "@/state/hooks";
import Alert from "@/components/elements/Alert";
import usePopupModal from "@/state/modal/usePopupModal";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

type Props = {
  item: Cart;
  key: number;
}

const ShoppingCartItem = ({
  item
}: Props) => {
  const dispatch = useDispatch();
  const popupModal = usePopupModal();
  const queryClient = useQueryClient();
  const { cartContent } = useAppSelector((state) => state.cart);
  const [count, setQuantity] = useState(0);

  const CartErrorPopup = (message: string) => {
    popupModal.open(
      <Alert
        buttons={Alert.ButtonVariants.OK}
        onClose={popupModal.close}
        message={message}
        title="Cart Error"
      />,
    );
  };

  const increaseCount = useMutation([`increaseCount-${item.product_id}`], {
    mutationFn: async () => {
      for (const cart of cartContent) {
        if (cart.product_id === item.product_id) {
          await api.put(`/api/v1/carts/${item.product_id}`, {
            quantity: cart.quantity + 1
          });
          return;
        }
      }
    },
    onSuccess: () => {
      dispatch(updateCount({ product_id: item.product_id, quantity: item.quantity + 1 }));
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 422)
        CartErrorPopup("The request quantity surpasses product stock.");
      if (status === 500)
        CartErrorPopup("Internal Server Error.");
    },
  });

  const decreaseCount = useMutation([`decreaseCount-${item.product_id}`], {
    mutationFn: async () => {
      if (item.quantity === 1) {
        CartErrorPopup("The quantity cannot be less than zero.");
        removeCart.mutate();
        throw new Error();
      }

      for (const cart of cartContent) {
        if (cart.product_id === item.product_id) {
          await api.put(`/api/v1/carts/${item.product_id}`, {
            quantity: cart.quantity - 1
          });
          return;
        }
      }
    },
    onSuccess: () => {
      dispatch(updateCount({ product_id: item.product_id, quantity: item.quantity - 1 }));
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 422)
        CartErrorPopup("The request quantity surpasses product stock.");
      if (status === 500)
        CartErrorPopup("Internal Server Error.");
    },
  });

  const removeCart = useMutation([`removeCart-${item.product_id}`], {
    mutationFn: async () => {
      await api.delete(`/api/v1/carts/${item.product_id}`);
    },
    onSuccess: () => {
      dispatch(removeItem(item.product_id));
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 500)
        CartErrorPopup("Internal Server Error.");
    },
  });

  const setCount = useMutation([`setCount-${item.product_id}`], {
    mutationFn: async () => {
      if (count < 0) {
        CartErrorPopup("The quantity cannot be less than zero.");
        throw new Error("The quantity cannot be less than zero.");
      }

      for (const cart of cartContent) {
        if (cart.product_id === item.product_id) {
          await api.put(`/api/v1/carts/${item.product_id}`, {
            count
          });
          return;
        }
      }
    },
    onSuccess: () => {
      dispatch(updateCount({ product_id: item.product_id, quantity: item.quantity }));
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: (data: AxiosError) => {
      const status = data.response?.status;
      if (status === 422)
        CartErrorPopup("The request quantity surpasses product stock.");
      if (status === 500)
        CartErrorPopup("Internal Server Error.");
    },
  });

  return (
    <>
      <ul className="flex flex-col divide-y divide-gray-700">
        <li className="flex flex-col p-2 sm:flex-row sm:justify-between">
          <div className="flex w-full space-x-2 sm:space-x-4">
            <img className="flex-shrink-0 object-cover w-20 h-20 dark:border-transparent rounded outline-none sm:w-32 sm:h-32 dark:bg-gray-500" src="https://images.unsplash.com/photo-1526170375885-4d8ecf77b99f?ixlib=rb-1.2.1&amp;ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;auto=format&amp;fit=crop&amp;w=1350&amp;q=80" alt="Polaroid camera" />
            <div className="flex flex-col justify-between w-full pb-4">
              <div className="flex justify-between w-full pb-2 space-x-2">
                <div className="space-y-1">
                  <h3 className="text-lg font-semibold leadi sm:pr-8">{item.title}</h3>
                  <p className="text-sm dark:text-gray-400">{item.description}</p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold">{(parseFloat(`${item.price_gross}`) / 100).toFixed(2)}€</p>
                  <p className="text-sm line-through dark:text-gray-600">{(parseFloat(`${item.price_net}`) / 100).toFixed(2)}€</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex text-sm divide-x">
                  <button
                    onClick={() => removeCart.mutate()}
                    type="button"
                    className="flex items-center px-2 py-1 pl-0 space-x-1"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 fill-current">
                      <path d="M96,472a23.82,23.82,0,0,0,23.579,24H392.421A23.82,23.82,0,0,0,416,472V152H96Zm32-288H384V464H128Z"></path>
                      <rect width="32" height="200" x="168" y="216"></rect>
                      <rect width="32" height="200" x="240" y="216"></rect>
                      <rect width="32" height="200" x="312" y="216"></rect>
                      <path d="M328,88V40c0-13.458-9.488-24-21.6-24H205.6C193.488,16,184,26.542,184,40V88H64v32H448V88ZM216,48h80V88H216Z"></path>
                    </svg>
                    <span>Remove</span>
                  </button>
                  <button type="button" className="flex items-center px-2 py-1 space-x-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 fill-current">
                      <path d="M453.122,79.012a128,128,0,0,0-181.087.068l-15.511,15.7L241.142,79.114l-.1-.1a128,128,0,0,0-181.02,0l-6.91,6.91a128,128,0,0,0,0,181.019L235.485,449.314l20.595,21.578.491-.492.533.533L276.4,450.574,460.032,266.94a128.147,128.147,0,0,0,0-181.019ZM437.4,244.313,256.571,425.146,75.738,244.313a96,96,0,0,1,0-135.764l6.911-6.91a96,96,0,0,1,135.713-.051l38.093,38.787,38.274-38.736a96,96,0,0,1,135.765,0l6.91,6.909A96.11,96.11,0,0,1,437.4,244.313Z"></path>
                    </svg>
                    <span>Add to favorites</span>
                  </button>
                </div>
                <div className="flex justify-center w-1/5">
                  <button onClick={() => decreaseCount.mutate()}>
                    <svg className="fill-current text-gray-600 w-3" viewBox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                    </svg>
                  </button>

                  <input
                    value={item.quantity}
                    onChange={e => setQuantity(parseInt(e.target.value))}
                    onKeyDown={ele => { if (ele.key === "Enter") setCount.mutate(); }}
                    className="h-7 border text-center w-8 p-0 mx-1"
                    type="text"
                  />

                  <button onClick={() => increaseCount.mutate()}>
                    <svg className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                      <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul >
    </>
  );
};

export default ShoppingCartItem;