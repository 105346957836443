export const formatDate = (dateString: string, short?: boolean) => {
  const date = new Date(dateString);
  return date.toLocaleString("de-DE", {
    year: "numeric",
    month: short ? "numeric" : "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
