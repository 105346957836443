import React from "react";
import { Product } from "@/utils/product";
import { useInfiniteQuery } from "@tanstack/react-query";
import ShowMoreButton from "@components/elements/input/ShowMoreButton";
import MarketplaceShopAllItem from "@pages/marketplace/shop/MarketplaceShopAllItem";
import api from "@/api/api";

type Response = {
  data: Product[];
  current_page: number;
  total_pages: number;
};

type Props = {
  searchQuery: string;
};

const MarketplaceShopAllContainer = ({ searchQuery }: Props) => {
  const products = useInfiniteQuery<Response>(
    ["marketplace-shop_all", searchQuery],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/api/v1/marketplace/products?page=${pageParam}&q=${searchQuery}`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : false;
      },
    }
  );

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {products.data &&
          products.data.pages.map((page) =>
            page.data && page.data.map((item: Product) => (
              <MarketplaceShopAllItem key={item.id} product={item} />
            ))
          )}
      </div>
      {products.hasNextPage && !products.isFetchingNextPage && (
        <ShowMoreButton onClick={() => products.fetchNextPage()} />
      )}
    </>
  );
};

export default MarketplaceShopAllContainer;
