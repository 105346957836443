import React, { useEffect, useState, createContext } from "react";
import Router from "@/routes/Router";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ReportModal from "@components/elements/main/ReportModal";
import BlockUserModal from "@components/elements/main/BlockUserModal";
import LoginExtendedPopup from "@components/popup/LoginExtendedPopup";
import PaymentIntegrationPopup from "@components/popup/PaymentIntegrationPopup";
import useLoginModal from "@/state/modal/useLoginModal";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "@/state/store";
import init from "@/i18n";
import { Toaster } from "react-hot-toast";
import { NotificationContextProvider } from "@/contexts/NotificationContext";
import usePaymentModal from "@/state/modal/usePaymentModal";
import { useAppSelector } from "@/state/hooks";

function FallbackComponent() {
  return <div>An error has occured</div>;
}

type FullscreenType = {
  fullscreen: boolean;
  setFullscreen?: (fullscreen: boolean) => void;
  topNavHidden: boolean;
  setTopNavHidden?: (topNavHidden: boolean) => void;
  bottomNavHidden: boolean;
  setBottomNavHidden?: (bottomNavHidden: boolean) => void;
  borderless: boolean;
  setBorderless?: (borderless: boolean) => void;
  darkTheme: boolean;
  setDarkTheme?: (darkTheme: boolean) => void;
};

export const AppContext = createContext<FullscreenType | null>(null);

const loginBlacklist: Array<string> = [
  "/login",
  "/registrierung",
  "/verwaltung/passwort",
  "/info",
  "/help",
  "/marketing",
  "/impressum",
  "/datenschutzrichtlinie",
  "/agb",
  "/business",
  "/glossary",
  "/getapp",
];

const paymentBlacklist: Array<string> = [
  "/login",
  "/registrierung",
  "/verwaltung/passwort",
  "/info",
  "/help",
  "/marketing",
  "/impressum",
  "/datenschutzrichtlinie",
  "/agb",
  "/business",
  "/glossary",
  "/getapp",
];

const App = () => {
  const location = useLocation();
  const [fullscreenMode, setFullscreenMode] = useState<boolean>(false);
  const [topNavHiddenMode, setTopNavHiddenMode] = useState<boolean>(false);
  const [bottomNavHiddenMode, setBottomNavHiddenMode] =
    useState<boolean>(false);
  const [borderlessMode, setBorderlessMode] = useState<boolean>(false);
  const [darkThemeMode, setDarkThemeMode] = useState<boolean>(false);
  const loginModal = useLoginModal();
  const paymentModal = usePaymentModal();
  const fullpath = useLocation().pathname;
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    // HubSpot tracking
    if ((window as any)._hsq) {
      (window as any)._hsq.push(["setPath", location.pathname]);
      (window as any)._hsq.push(["trackPageView"]);
    }
    setFullscreenMode(false);
    setTopNavHiddenMode(false);
    setBottomNavHiddenMode(false);
    setBorderlessMode(false);
    setDarkThemeMode(false);
  }, [location]);

  const onBeforeLift = async () => {
    await init();
  };

  return (
    <PersistGate
      onBeforeLift={onBeforeLift}
      loading={null}
      persistor={persistor}
    >
      <NotificationContextProvider>
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
          <AppContext.Provider
            value={{
              fullscreen: fullscreenMode,
              setFullscreen: setFullscreenMode,
              topNavHidden: topNavHiddenMode,
              setTopNavHidden: setTopNavHiddenMode,
              bottomNavHidden: bottomNavHiddenMode,
              setBottomNavHidden: setBottomNavHiddenMode,
              borderless: borderlessMode,
              setBorderless: setBorderlessMode,
              darkTheme: darkThemeMode,
              setDarkTheme: setDarkThemeMode,
            }}
          >
            <Toaster />
            <Router />
            <ReportModal />
            <BlockUserModal />
            {loginModal.isOpen && !loginBlacklist.includes(fullpath) && (
              <LoginExtendedPopup />
            )}
            {user &&
              paymentModal.isOpen &&
              !paymentBlacklist.includes(fullpath) && (
                <PaymentIntegrationPopup />
              )}
          </AppContext.Provider>
        </Sentry.ErrorBoundary>
      </NotificationContextProvider>
    </PersistGate>
  );
};

export default Sentry.withProfiler(App);
