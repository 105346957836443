import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cart } from "@/utils/cart";


interface CartState {
  cartContent: Array<Cart>
}

const initialState: CartState = {
  cartContent: []
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, { payload }: PayloadAction<Cart[]>) => {
      state.cartContent = payload;
    },

    addItem: (state, { payload }: PayloadAction<Cart>) => {
      for (const item of state.cartContent) {
        if (item.product_id === payload.product_id) {
          ++item.quantity;
          return;
        }
      }
      state.cartContent.push(payload);
    },

    updateCount: (state, { payload }: PayloadAction<{ product_id: string, quantity: number }>) => {
      state.cartContent.forEach(item => {
        if (item.product_id === payload.product_id) {
          item.quantity = payload.quantity;
        }
      });
    },

    removeItem: (state, { payload }: PayloadAction<string>) => {
      const updated = state.cartContent.filter(item => item.product_id !== payload);
      state.cartContent = updated;
    }
  },
});

export const { setCart, addItem, updateCount, removeItem } = cartSlice.actions;

export default cartSlice.reducer;
