import React from "react";
import { useMutation } from "@tanstack/react-query";
import Button from "@components/elements/input/Button";
import PopupWrapper from "@components/elements/PopupWrapper";
import usePaymentModal from "@/state/modal/usePaymentModal";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import api from "@/api/api";

const PaymentPopup = () => {
  const paymentModal = usePaymentModal();
  const popupModal = usePopupModal();

  const paymentMutation = useMutation(["payment-integration"], {
    mutationFn: async () => {
      const res = await api.get("/api/v1/payment");
      return res.data;
    },
    onSuccess: (data) => {
      const account_link = data.account_link;
      setTimeout(
        () => window.location.assign(account_link),
        200,
      );
      paymentModal.close();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Try again in a few minutes."
          title="Payment Integration"
        />,
      );
    },
  });

  return (
    <PopupWrapper onClose={() => paymentModal.close()}>
      <div className="w-full md:max-w-[310px]">
        <div className="grid grid-flow-row gap-1 text-center justify-center text-black">
          <div className="text-darkblue font-medium text-xl">
            TRADEFOOX <br /> Payment Integration
          </div>
          <Button
            submit
            onClick={() => {
              if (!paymentMutation.isLoading) paymentMutation.mutate();
            }}
          >
            Integrate
          </Button>
          <Button
            onClick={() => {
              paymentModal.close();
            }}
            variant={Button.Variants.Cyan}
          >
            Cancel
          </Button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default PaymentPopup;
