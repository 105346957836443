import React from "react";
import { NavLink } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  fontStyle?: TwStyle;
};

const Footer = ({ fontStyle }: Props) => {
  const footerMenu = [
    {
      category: "User",
      links: [
        {
          href: "info",
          hovertext: "Profildaten bearbeiten",
          title: "Info",
        },
        { href: "help", hovertext: "Hilfebereich", title: "Hilfebereich" },
        {
          href: "marketing",
          hovertext: "Marketing",
          title: "Marketing",
        },
      ],
    },
    {
      category: "Website",
      links: [
        {
          href: "impressum",
          hovertext: "Impressum",
          title: "Impressum",
        },
        { href: "privacy", hovertext: "Datenschutz", title: "Datenschutz" },
        { href: "conditions", hovertext: "AGB", title: "AGB" },
      ],
    },
    {
      category: "Marketing",
      links: [
        {
          href: "business",
          hovertext: "Business",
          title: "Business-Service",
        },
        {
          href: "glossary",
          hovertext: "Glossar",
          title: "Glossar",
        },
      ],
    },
    {
      category: "App",
      links: [{ href: "mehr", hovertext: "Mehr", title: "Mehr" }],
    },
  ];

  return (
    <div className="text-gray-900 text-sm">
      <div className="flex flex-wrap gap-y-2 overflow-hidden justify-center mb-2">
        {footerMenu.map((footerLinks, index) => {
          return (
            <div key={index}>
              {footerLinks.links.map((links, index) => {
                return (
                  <NavLink
                    to={links.href}
                    key={index}
                    title={links.hovertext}
                    css={[tw`font-medium hover:bg-gray-200 px-1`, fontStyle]}
                  >
                    {links.title}
                  </NavLink>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="flex flex-row relative w-[150px] h-[30px] mx-auto">
        <img src="/logo.gif" alt="TRADEFOOX" />
        <div className="absolute text-mini -right-4 text-darkblue">© 2022</div>
      </div>
    </div>
  );
};

export default Footer;
