import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { HeartIcon, PrinterIcon } from "@heroicons/react/24/outline";
import {
  HeartIcon as HeartIconFull,
  BookmarkIcon as BookmarkIconFull,
} from "@heroicons/react/24/solid";
import {
  BookmarkIcon,
  EyeIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import api from "@/api/api";
import { useQuery } from "@tanstack/react-query";
import { Product } from "@/utils/product";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";

type RatePropType = {
  rating: number;
}

const StarRating = ({ rating }: RatePropType) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= Math.floor(rating)) {
      // Full star
      stars.push(<FontAwesomeIcon icon={faStarSolid} key={i} />);
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      // Half star
      stars.push(<FontAwesomeIcon icon={faStarHalfAlt} key={i} />);
    } else {
      // Empty star
      stars.push(<FontAwesomeIcon icon={faStarRegular} key={i} />);
    }
  }

  return <div>{stars}</div>;
};

const MarketplaceDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const product = useQuery<Product, Error>(
    [`product-detail-${id}`],
    async () => {
      const res = await api.get(`/api/v1/marketplace/products/${id}`);
      return res.data;
    });

  if (!product.isSuccess) return <span>Loading...</span>;

  return (
    <div>
      {/* Title */}
      <div className="flex flex-row justify-between items-start">
        <div className="text-xl">
          {product.data?.title}
        </div>
        <div className="inline-flex gap-3">
          <button className="text-darkblue bg-white rounded-md border-darkblue border">
            <HeartIconFull className="w-5 h-5 heroicon-stroke-w-2.0 text-red-600" />
          </button>

          <button className="button--primary">Kontaktieren</button>

          <button className="button--primary">
            <BookmarkIconFull className="w-5 h-5 heroicon-stroke-w-2.0" />
          </button>

          <button className="button--primary">
            <PrinterIcon className="w-5 h-5 heroicon-stroke-w-2.0" />
          </button>

          <NavLink to="/publictender/">
            {t("main.groupsInfoForm.buttons.back")}
          </NavLink>
        </div >
      </div >

      {/* Stats */}
      < div className="flex flex-row w-full justify-between border-b pb-2 mb-4" >
        <div className="flex flex-col gap-1">
          <div className="whitespace-nowrap inline-flex items-center gap-2">
            <StarRating rating={4.3} />
            <div className="text-gray-600 text-xs">710 Sternebewertungen</div>
          </div>
          <div className="inline-flex w-full gap-2 text-xs">
            <div className="inline-flex gap-0.5 items-center">
              <EyeIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">4</div>
            </div>
            <div className="inline-flex gap-0.5 items-center">
              <BookmarkIcon className="w-3 h-3 text-darkblue heroicon-stroke-w-2.0" />
              <div className="text-gray-500">1</div>
            </div>
            <div className="inline-flex gap-0.5 items-center">
              <HeartIcon className="w-3 h-3 text-darkred heroicon-stroke-w-2.0" />
              <div className="text-gray-500">2</div>
            </div>
          </div>
        </div >
      </div >

      <div className="flex flex-row gap-6 w-full">
        {/* Left side */}
        {/* Image */}
        <div className="flex-1">
          <div className="relative pb-[100%] w-full">
            <img src={product.data?.image_url} alt="Product" className=" absolute h-full w-full object-cover object-center" />
          </div>
        </div>
        {/* Right side */}
        <div className="flex flex-col gap-8 text-gray-800 w-[360px] flex-none">
          {/* Info */}
          <div
            className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="flex flex-col">
              <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap text-lg">
                {(parseFloat(`${product.data?.price_gross}`) / 100).toFixed(2)} €
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Nettopreis</b>{" "}
                {(parseFloat(`${product.data?.price_net}`) / 100).toFixed(2)} €
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Bruttopreis</b>{" "}
                {(parseFloat(`${product.data?.price_gross}`) / 100).toFixed(2)} €
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Versand</b>{" "}
                {product.data?.brand}
              </div>
              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Anzahl</b>{" "}
                <div className="font-semibold">Derzeit nicht verfügbar.</div>
              </div>

              <div className="text-sm text-gray-800">
                <b className="whitespace-nowrap float-left mr-1">Ab 18</b>
              </div>
              <div className="text-sm text-gray-800 inline-flex gap-2 mt-2">
                <button className="button--primary">Einpacken</button>
                <button className="button--secondary">Kaufen</button>
              </div>
            </div>
          </div>


          <div
            className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit">
            <div className="flex flex-col">
              <div className="font-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap">
                Verkäufer & Versand
              </div>
              <div className="text-sm text-gray-800">
                <NavLink to={"/profile/100"} className="whitespace-nowrap">
                  Linus
                </NavLink>
              </div>
            </div>
          </div>

          {/* Order description */}
          <div className="rounded-2xl border-gray-200 border px-2 py-1 bg-gray-100 flex flex-col text-sm h-fit text-sm">
            <div className="flex flex-col text-gray-800">
              <div className="ont-bold border-b pb-2 mb-2 border-gray-300 whitespace-nowrap block font-semibold">Beschreibung</div>
              <div className="break-all">
                {product.data?.description}
              </div>
            </div>
          </div>
          <div className="cursor-pointer border w-full border-gray-300 inline-flex items-center justify-center gap-1 my-2 text-sm">
            Einklappen
            <ChevronUpIcon className="w-4 h-4 heroicon-stroke-w-2.4" />
          </div>
        </div>
      </div>
    </div >
  );
};

export default MarketplaceDetails;
