const driverlicenseOptions = [
  { name: "A1", value: "A1" },
  { name: "A", value: "A" },
  { name: "B", value: "B" },
  { name: "C1", value: "C1" },
  { name: "C", value: "C" },
  { name: "D1", value: "D1" },
  { name: "D", value: "D" },
  { name: "BE", value: "BE" },
  { name: "C1E", value: "C1E" },
  { name: "CE", value: "CE" },
  { name: "D1E", value: "D1E" },
  { name: "DE", value: "DE" },
  { name: "M", value: "M" },
  { name: "L", value: "L" },
  { name: "T/S", value: "T/S" },
];

export default driverlicenseOptions;
