import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  PlayIcon,
  PauseIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/24/outline";
import {
  PlayIcon as PlayIconFull,
  ChatBubbleOvalLeftIcon,
  PlusIcon,
  HeartIcon,
  MusicalNoteIcon,
} from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";
import WatchBookmarkButton from "@/pages/watch/actions/WatchBookmarkButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import SharePopup from "@/components/popup/SharePopup";
import WatchType from "@/utils/types/watch/WatchType";
import ProfileAvatar from "@components/elements/shared/ProfileAvatar";
import WatchDropdown from "@pages/watch/WatchDropdown";
import api from "@/api/api";
import useLoginModal from "@/state/modal/useLoginModal";
import CommentsPopup from "@/components/popup/CommentsPopup";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";
import { useTranslation } from "react-i18next";
import Hls from "hls.js";

type Props = {
  data: WatchType;
  active: boolean;
  preload: boolean;
  fetch: boolean;
  autoplay?: boolean;
  key?: string;
  muted?: boolean;
  setMuted?: (muted: boolean) => void;
  queryKey?: (string | null)[];
};

const Watch = ({
  data,
  active,
  autoplay,
  muted = true,
  setMuted,
  preload,
  fetch,
  queryKey = ["watch", "posts"],
}: Props) => {
  const queryClient = useQueryClient();
  const [viewed, setViewed] = useState(false);
  const [playing, setPlaying] = useState(autoplay);
  const [canPlay, setCanPlay] = useState(false);
  const [preventPause, setPreventPause] = useState(false);
  const [timeRatio, setTimeRatio] = useState(0);
  const { user } = useAppSelector((state) => state.user);
  const loginModal = useLoginModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const like = useMutation([`like-${data.post_id}`], {
    mutationFn: async () => {
      if (!user) {
        loginModal.open();
        return;
      }
      const liked = data.liked;

      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData<{
        pages: Array<{ data: WatchType[] }>;
      }>(queryKey);

      if (previousData) {
        for (const page of previousData.pages) {
          for (const post of page.data) {
            if (post.post_id !== data.post_id) continue;

            if (data.liked) {
              post.like_count -= 1;
            } else {
              post.like_count += 1;
            }
            post.liked = !liked;
          }
        }
        queryClient.setQueryData(queryKey, previousData);
      }
      const res = await api.get(
        `/api/v1/posts/${data.post_id}/${liked ? "unlike" : "like"}`,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["watch", "posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (active && !viewed) {
      setViewed(true);
      axios.get(`/api/v1/posts/${data.post_id}/view`);
    }
  }, [active, data.post_id, viewed]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [sharePopupMobile, setSharePopupMobile] = useState(false);
  const [likeEffect, setLikeEffect] = useState(false);

  useEffect(() => {
    if (video && active) {
      video.play();
    }
    if (video && !active) {
      video.pause();
    }
  }, [active]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const video = videoRef.current;

  const videoUrl = `https://customer-81whkaiz8icchqgz.cloudflarestream.com/${data.media_key}/manifest/video.m3u8`;

  const hls = useMemo(() => {
    const video = videoRef.current;

    if (video && Hls.isSupported()) {
      const hls = new Hls({
        autoStartLoad: false,
      });

      hls.attachMedia(video);

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {});

      hls.on(Hls.Events.ERROR, function (event, data) {
        const errorType = data.type;
        const errorDetails = data.details;
        const errorFatal = data.fatal;
      });

      return hls;
    }
    return null;
  }, [videoRef.current]);

  useEffect(() => {
    if (hls) {
      if (fetch) {
        const loadAndPlayVideo = () => {
          hls.startLoad();
          if (active) {
            video?.play();
          }
        };

        if (hls.levels.length === 0) {
          hls.loadSource(videoUrl);
          hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
            loadAndPlayVideo();
          });
        } else {
          loadAndPlayVideo();
        }
      } else {
        hls.stopLoad();
      }
    }

    // return () => hls.detachMedia();
  }, [hls, fetch]);

  return (
    <div className="relative snap-start group">
      {/* Overlay */}
      <div className="absolute inset-0 cursor-pointer">
        {/* Footer */}
        <div className="absolute bottom-0 left-0 w-full block lg:hidden pointer-events-none">
          <div className="flex flex-row items-center gap-1 text-white text-sm rounded-xl py-1 pointer-events-auto">
            <div className="scale-[66%] pointer-events-auto">
              <ProfileAvatar
                user={{
                  id: data.author_id,
                  name: data.author_name,
                  type: data.author_type,
                  avatar: data.author_avatar,
                }}
                nameStyle={tw`hidden`}
              />
            </div>

            <div className="flex flex-col w-full">
              <NavLink
                to={`/watch/c/${data.author_id}`}
                className="hover:font-semibold w-fit pointer-events-auto"
              >
                @{data.author_name}
              </NavLink>
              <div className="line-clamp-2 overflow-clip overflow-ellipsis leading-4">
                {data.post_text}
              </div>
              <div className="flex flex-row">
                <MusicalNoteIcon className="flex-shrink-0 w-5 h-5" />
                <div className="w-full overflow-hidden">
                  <div className="w-full animate-marquee"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar */}
        <div className="absolute z-10 right-[10px] bottom-[70px] w-[50px] rounded-xl pointer-events-none text-xs text-gray-200">
          <div className="flex flex-col items-center gap-3">
            <button
              css={[tw`flex flex-col items-center pointer-events-auto`]}
              onClick={() => like.mutate()}
            >
              {data.liked ? (
                <HeartIcon
                  className={`w-8 h-8 text-red-600 ${
                    likeEffect && "animate-ping-once"
                  }`}
                />
              ) : (
                <HeartIcon
                  className="w-8 h-8"
                  onClick={() => {
                    setLikeEffect(true);
                  }}
                  onAnimationEnd={() => {
                    setLikeEffect(false);
                  }}
                />
              )}
              <div>{data.like_count}</div>
            </button>
            <div className="flex flex-col items-center pointer-events-auto">
              <button
                className="flex flex-col items-center gap-0"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ChatBubbleOvalLeftIcon
                  className="w-8 h-8"
                  onClick={() => {
                    setCommentsPopupOpen(!commentsPopupOpen);
                  }}
                />
              </button>
              <div>{data.comment_count}</div>
            </div>
            <div className="flex flex-col items-center pointer-events-auto">
              <WatchBookmarkButton
                postId={data.post_id}
                bookmarked={data.bookmarked}
              />
              <div>{data.bookmark_count}</div>
            </div>
            <div
              className="flex flex-col items-center pointer-events-auto"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <PlayIconFull className="w-8 h-8" />
              <div>{data.view_count}</div>
            </div>
            <button
              className="relative flex flex-col items-center cursor-pointer pointer-events-auto"
              onClick={(e) => {
                if (user) {
                  setSharePopupOpen(true);
                } else {
                  loginModal.open();
                }
                e.stopPropagation();
              }}
            >
              <ArrowPathRoundedSquareIcon className="w-8 h-8" />
              <div>0</div>
              <button
                className="lg:hidden absolute top-0 left-0 w-full h-full"
                onClick={() => {
                  setSharePopupMobile(true);
                }}
              />
            </button>

            <div className="pointer-events-auto text-black">
              {
                <WatchDropdown
                  isRepost={data.is_reposted}
                  postId={data.post_id}
                  authorId={data.author_id}
                  bookmarked={data.bookmarked}
                />
              }
            </div>
            <button
              className="flex gap-2.5 items-center border rounded-xl px-2.5 mr-12 py-1 bg-gray-200 text-black pointer-events-auto text-sm mt-4"
              onClick={() => navigate("/watch/create")}
            >
              <PlusIcon className="w-4 h-4 text-black" />{" "}
              {t("main.watchTabs.create.buttons.createButton")}
            </button>
          </div>
        </div>
        <div className="absolute z-10 bottom-[10px] w-[calc(100%-20px)] mx-[10px]">
          <input
            type="range"
            min={0}
            max={100}
            value={timeRatio}
            onChange={(e) => {
              setPreventPause(true);
              setTimeout(() => {
                setPreventPause(false);
              }, 400);
              if (!video) return;
              video.currentTime =
                (video.duration * parseFloat(e.currentTarget.value)) / 100;
              setTimeRatio(parseInt(e.currentTarget.value));
            }}
            defaultValue={0}
            className="accent-black invert brightness-[65%] h-0.5 w-full pointer-events-auto cursor-pointer opacity-0 group-hover:opacity-100 duration-200"
          />
        </div>
        <div className="absolute z-10 px-6 pb-4 bottom-[20px] w-full flex flex-row justify-between rounded-xl text-gray-200">
          <button
            className="pointer-events-auto opacity-0 group-hover:opacity-100 duration-200"
            onClick={() => {
              if (!video) return;
              video.paused ? video.play() : video.pause();
            }}
          >
            {playing ? (
              <PauseIcon className="w-8 h-8 " />
            ) : (
              <PlayIcon className="w-8 h-8 " />
            )}
          </button>
          <button
            className="pointer-events-auto opacity-0 group-hover:opacity-100 duration-200"
            onClick={(e) => {
              setMuted && setMuted(!muted);
              e.stopPropagation();
            }}
          >
            {muted ? (
              <SpeakerXMarkIcon className="w-8 h-8 " />
            ) : (
              <SpeakerWaveIcon className="w-8 h-8 " />
            )}
          </button>
        </div>
      </div>
      <div
        css={[
          tw`bg-neutral-900 h-[calc((100dvw-32px)/9*16)] lg:h-[calc(100dvh-180px)]`,
        ]}
      >
        <div
          className="w-full absolute top-1/2 h-full -translate-y-1/2 cursor-pointer"
          onClick={() => {
            if (!video || preventPause) return;
            video.paused ? video.play() : video.pause();
          }}
        >
          <video
            className={`w-full h-full object-contain ${
              !canPlay ? "hidden" : "block"
            }`}
            muted={muted}
            loop
            controls={false}
            autoPlay={autoplay}
            ref={videoRef}
            onCanPlay={() => setCanPlay(true)}
            onPlaying={() => setPlaying(true)}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onTimeUpdate={() => {
              if (!video) return;
              setTimeRatio((video.currentTime / video.duration) * 100);
            }}
          ></video>
          <img
            className="w-full h-full object-contain"
            src={data.media_thumbnail}
          />
        </div>
      </div>
      {sharePopupOpen && (
        <SharePopup
          postId={data.post_id}
          userId={data.author_id}
          isMobile={sharePopupMobile}
          content={{
            title: "Watch",
            body: "https://www.tradefoox.com/watch/" + data.post_id,
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      {commentsPopupOpen && (
        <CommentsPopup
          onClose={() => setCommentsPopupOpen(false)}
          comment_count={data.comment_count}
          postId={data.post_id}
        />
      )}
    </div>
  );
};

export default Watch;
