import React, { Fragment, useState } from "react";
import Input from "@/components/elements/input/Input";
import PopularWidget from "@/components/elements/PopularWidget";
import Tab from "@/components/menu/Tab";
import MessengerContainer from "@/pages/messenger/MessengerContainer";
import Checkbox from "@/components/elements/input/Checkbox";
import Button from "@/components/elements/input/Button";
import MarketplaceCreateContainer from "@/pages/marketplace/MarketplaceCreateContainer";
import TabMenu from "@components/elements/shared/tab/TabMenu";
import TabLink from "@components/elements/shared/tab/TabLink";
import { useTranslation } from "react-i18next";
import MarketplaceShopAllContainer from "@/pages/marketplace/shop/MarketplaceShopAllContainer";
import useDebounce from "@/utils/useDebounce";
import ShoppingCartContainer from "@/pages/marketplace/shop/ShoppingCartContainer";

type Props = {
  mainFilter?: string;
  topTab?: string;
  subTab?: string;
};

type billType = {
  id: string;
  creationDate: string;
  company: string;
  orderid: string;
  checked: boolean;
};

const MarketplaceContainer = ({
  mainFilter = "main_marketplace",
  topTab = "shop",
  subTab = "all",
}: Props) => {
  const [checkAll, setCheckAll] = useState(false);
  const [bills, setBills] = useState([]);
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const ordersForm = () => {
    return (
      <Fragment>
        {orders.length > 0 && (
          <div className="flex flex-col border border-slate-400">
            <div className="bg-slate-200">
              <div className="flex flex-row gap-x-8 text-xs font-semibold whitespace-nowrap p-2">
                <div className="flex flex-col">
                  <div>BESTELLUNGEN AUFGEGEBEN</div>
                  <div>dd. MMMM yyyy</div>
                </div>

                <div className="flex flex-col">
                  <div>SUMME</div>
                  <div>xxx €</div>
                </div>

                <div className="flex flex-col w-full text-right text-ellipsis overflow-hidden">
                  <div className="flex justify-end">BESTELL-NR.</div>
                  <div className="flex flex-row items-center justify-end">
                    <div className="pr-2 border-r border-gray-500">
                      Bestelldetails
                    </div>
                    <select className="py-0 pl-2 bg-transparent border-0 text-xs ">
                      <option>Rechnung</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 p-2 text-sm">
              <div className="flex flex-row justify-between">
                {/* Article */}
                <div className="flex flex-col">
                  <div className="font-bold">Zugestellt: dd MMMM yyyy</div>
                  <div className="mb-2">Ihre Sendung wurde zugestellt</div>
                  <div className="flex flex-row gap-3">
                    <img
                      className="w-[65px] h-[65px] object-cover"
                      src="/placeholder.png"
                    />
                    <div className="flex flex-col text-xs">
                      <div>TITLE</div>
                      <div>Verkauf durch:</div>
                      <div>Rückgaben sind bis zum mm.dd.yyyy möglich</div>
                      <div>EUR xx,xx</div>
                    </div>
                  </div>
                  <div className="w-fit">
                    <Button
                      onClick={() => {
                        console.log("click");
                      }}
                      size={Button.Sizes.Small}
                      variant={Button.Variants.Transparent}
                    >
                      Nochmals kaufen
                    </Button>
                  </div>
                </div>

                {/* Sidebuttons */}
                <div className="flex flex-col">
                  <Button
                    onClick={() => {
                      console.log("click");
                    }}
                    size={Button.Sizes.Small}
                    variant={Button.Variants.Transparent}
                  >
                    Lieferung verfolgen
                  </Button>
                  <Button
                    onClick={() => {
                      console.log("click");
                    }}
                    size={Button.Sizes.Small}
                    variant={Button.Variants.Transparent}
                  >
                    Artikel zurückgeben
                  </Button>
                  <Button
                    onClick={() => {
                      console.log("click");
                    }}
                    size={Button.Sizes.Small}
                    variant={Button.Variants.Transparent}
                  >
                    Produktrezension
                  </Button>
                  <Button
                    onClick={() => {
                      console.log("click");
                    }}
                    size={Button.Sizes.Small}
                    variant={Button.Variants.Transparent}
                  >
                    Kontaktieren
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const billsForm = () => {
    return (
      <Fragment>
        {bills.length > 0 && (
          <>
            <div className="grid grid-cols-[25px,1fr,1fr,1fr,1fr] p-1 text-sm font-semibold bg-white">
              <Checkbox
                name=""
                checked={checkAll}
                onChange={(checked) => {
                  const newBills = JSON.parse(JSON.stringify(bills));
                  newBills.forEach((element: any) => {
                    element.checked = checked;
                  });
                  setBills(newBills);
                  setCheckAll(checked);
                }}
              >
                {" "}
              </Checkbox>
              <div>Firma / Name</div>
              <div>Bestell-Nr.</div>
              <div>Datum</div>
              <div>Rechnungs-Nr.</div>
            </div>
            {bills.map((bill: billType, index) => {
              return (
                <div
                  key={bill.id}
                  className="grid grid-cols-[25px,1fr,1fr,1fr,1fr] divide-y px-1 text-xs font-semibold odd:bg-white even:bg-gray-200 ins items-center font-mono"
                >
                  <Checkbox
                    name=""
                    checked={bill.checked}
                    onChange={(checked) => {
                      const newBills = JSON.parse(JSON.stringify(bills));
                      newBills[index].checked = checked;
                      setBills(newBills);
                      setCheckAll(false);
                    }}
                  >
                    {" "}
                  </Checkbox>
                  <div>{bill.company}</div>
                  <div>{bill.orderid.toString().toUpperCase()}</div>
                  <div>
                    {new Date(bill.creationDate).toLocaleString("de-DE")}{" "}
                  </div>

                  {bill.id ? (
                    <div>{bill.id.toString().toUpperCase()}</div>
                  ) : (
                    <button className="bg-darkblue hover:bg-mediumblue rounded-lg text-white whitespace-nowrap font-sans">
                      Rechnung erstellen
                    </button>
                  )}
                </div>
              );
            })}
          </>
        )}
        {bills.length === 0 && <div>Noch keine Rechnungen vorhanden.</div>}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <PopularWidget></PopularWidget>
      <Tab isActive={mainFilter === "main_my_shop"}>
        <TabMenu>
          <TabLink to="/verwaltung/mein_shop/übersicht">Mein Shop</TabLink>
          <div className="max-lg:hidden">
            <TabLink to="/verwaltung/mein_shop/verkaufen">Erstellen</TabLink>
          </div>
          <TabLink to="/verwaltung/mein_shop/verkäufe">Verkäufe</TabLink>
          <TabLink to="/verwaltung/mein_shop/bestellungen">
            {t("main.shoppingVenture.orders")}
          </TabLink>
          <TabLink to="/verwaltung/mein_shop/statistik">Statistik</TabLink>
        </TabMenu>

        <div className="flex flex-col gap-2">
          <Input
            name="ads-keywords"
            placeholder="Shop durchsuchen"
            onChange={(e) => setSearchQuery(e)}
            clearable
          />

          <Tab isActive={topTab === "my_shop"}>
            <TabMenu subTab>
              <TabLink subTab to="/verwaltung/mein_shop/übersicht">
                {t("main.contacts.tabs.myContacts.tabs.overview")}
              </TabLink>
              <TabLink subTab to="/verwaltung/mein_shop/rechnungen">
                {t("main.navLinks.createAd.tabs.toYou.tabs.bills")}
              </TabLink>
              <TabLink subTab to="/verwaltung/mein_shop/messenger">
                Nachrichten
              </TabLink>
              <TabLink subTab to="/verwaltung/mein_shop/vorlagen">
                Vorlagen
              </TabLink>
              <TabLink subTab to="/verwaltung/mein_shop/zahlungsmethode">
                Zahlungsarten
              </TabLink>
            </TabMenu>

            <Tab isActive={subTab === "bills"}>{billsForm()}</Tab>
          </Tab>

          <Tab isActive={topTab === "create"}>
            <MarketplaceCreateContainer />
          </Tab>
          <Tab isActive={topTab === "sells"}></Tab>

          <Tab isActive={topTab === "stats"}></Tab>

          <Tab isActive={topTab === "orders"}>{ordersForm()}</Tab>
        </div>
      </Tab>

      <Tab isActive={mainFilter === "main_marketplace"}>
        <TabMenu>
          <TabLink to="/verwaltung/marketplace/für_dich">Für Dich</TabLink>
          <TabLink to="/verwaltung/marketplace/warenkorb">Warenkorb</TabLink>
          <TabLink to="/verwaltung/marketplace/wunschliste">
            Wunschliste
          </TabLink>
          <TabLink to="/marketplace/follower">Follower</TabLink>
          <TabLink to="/marketplace/trending">Trending</TabLink>
        </TabMenu>

        <div className="flex flex-col gap-2">
          <Input
            name="ads-keywords"
            placeholder={t(
              "main.navLinks.createAd.tabs.toYou.searchMarketplace",
            )}
            clearable
          />

          <Tab isActive={topTab === "shop"}>
            <TabMenu subTab>
              <TabLink subTab to="/verwaltung/marketplace/für_dich">
                {t("main.contacts.tabs.myContacts.tabs.overview")}
              </TabLink>
              <TabLink subTab to="/verwaltung/marketplace/rechnungen">
                {t("main.navLinks.createAd.tabs.toYou.tabs.bills")}
              </TabLink>
              <TabLink subTab to="/verwaltung/marketplace/kaufübersicht">
                {t("main.shoppingVenture.purchases")}
              </TabLink>
              <TabLink subTab to="/verwaltung/marketplace/wieder_kaufen">
                {t("main.navLinks.createAd.tabs.toYou.tabs.buyAgain")}
              </TabLink>
              <TabLink subTab to="/verwaltung/marketplace/bestellungen">
                {t("main.shoppingVenture.orders")}
              </TabLink>
              <TabLink subTab to="/verwaltung/marketplace/zuletzt_gesehen">
                {t("main.navLinks.createAd.tabs.toYou.tabs.lastSeen")}
              </TabLink>
            </TabMenu>

            <Tab isActive={subTab === "all"}>
              <MarketplaceShopAllContainer
                searchQuery={debouncedSearchQuery}
              />
            </Tab>
            <Tab isActive={subTab === "messenger"}>
              <MessengerContainer />
            </Tab>
            <Tab isActive={subTab === "bills"}>{billsForm()}</Tab>
            <Tab isActive={subTab === "orders"}>{ordersForm()}</Tab>

            <Tab isActive={topTab === "follower"}>
              <TabMenu subTab>
                <TabLink subTab to="/verwaltung/marketplace/für_dich">
                  {t("main.contacts.tabs.myContacts.tabs.overview")}
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/rechnungen">
                  {t("main.navLinks.createAd.tabs.toYou.tabs.bills")}
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/messenger">
                  Nachrichten
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/vorlagen">
                  Vorlagen
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/zahlungsmethode">
                  Zahlungsarten
                </TabLink>
              </TabMenu>
            </Tab>

            <Tab isActive={topTab === "trending"}>
              <TabMenu subTab>
                <TabLink subTab to="/verwaltung/marketplace/für_dich">
                  {t("main.contacts.tabs.myContacts.tabs.overview")}
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/rechnungen">
                  {t("main.navLinks.createAd.tabs.toYou.tabs.bills")}
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/messenger">
                  Nachrichten
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/vorlagen">
                  Vorlagen
                </TabLink>
                <TabLink subTab to="/verwaltung/marketplace/zahlungsmethode">
                  Zahlungsarten
                </TabLink>
              </TabMenu>

              <Tab isActive={subTab === "messenger"}>
                <MessengerContainer />
              </Tab>
            </Tab>
          </Tab>

          <Tab isActive={topTab === "cart"}>
            <ShoppingCartContainer />
          </Tab>
        </div>
      </Tab>

      <Tab stayVisible={true}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {/* Ads go here */}
        </div>
      </Tab>
    </Fragment>
  );
};

export default MarketplaceContainer;
