import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "@/state/store";
import { openModal, closeModal } from "@/state/modal/paymentModalSlice";

const usePaymentModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useSelector((state: RootState) => state.paymentModal.isOpen);

  const open = () => {
    dispatch(openModal());
  };

  const close = () => {
    dispatch(closeModal());
  };

  return { isOpen, open, close };
};

export default usePaymentModal;
