import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import MainWrapper from "@pages/MainWrapper";
import NewsContainer from "@pages/news/NewsContainer";
import AssignmentsContainer from "@pages/assignments/AssignmentsContainer";
import AssignmentsDetails from "@pages/assignments/AssignmentsDetails";
import LoginContainer from "@pages/auth/LoginContainer";
import RegisterContainer from "@pages/auth/RegisterContainer";
import JobsContainer from "@pages/jobs/JobsContainer";
import JobsDetails from "@pages/jobs/JobsDetails";
import ContactsContainer from "@pages/contacts/ContactsContainer";
import WatchContainer from "@pages/watch/WatchContainer";
import MarketplaceContainer from "@pages/marketplace/MarketplaceContainer";
import MarketplaceDetails from "@pages/marketplace/MarketplaceDetails";
import MessengerContainer from "@pages/messenger/MessengerContainer";
import LegalNoticeContainer from "@pages/legal/LegalNoticeContainer";
import ConditionsContainer from "@pages/legal/ConditionsContainer";
import PrivacyContainer from "@pages/legal/PrivacyContainer";
import TermsOfUseContainer from "@pages/legal/TermsOfUseContainer";
import CopyRightContainer from "@pages/legal/CopyRightContainer";
import CommunityRulesContainer from "@pages/legal/CommunityRulesContainer";
import HelpContainer from "@pages/legal/HelpContainer";
import MoreContainer from "@pages/legal/MoreContainer";
import StartupAdvice from "@pages/info/StartupAdvice";
import Successor from "@pages/info/Successor";
import LeisureMaps from "@/pages/info/LeisureMaps";
import Copyright from "@/pages/info/Copyright";
import Newspapers from "@pages/info/Newspapers";
import WatchFAQ from "@pages/info/WatchFAQ";
import MemberFAQ from "@pages/info/MemberFAQ";
import CompanyFAQ from "@pages/info/CompanyFAQ";
import Donations from "@pages/info/Donations";
import Newsletter from "@pages/info/Newsletter";
import Unsubscripe from "@pages/info/Unsubscripe";
import EventCalendar from "@pages/info/EventCalendar";
import Support from "@pages/info/Support";
import Partner from "@pages/info/Partner";
import IndustrialHistory from "@pages/info/IndustrialHistory";
import IndustrialHistoryDetails from "@/pages/info/IndustrialHistoryDetails";
import ActGlobal from "@/pages/info/ActGlobal";
import CreateAccount from "@/pages/info/CreateAccount";
import BookmarksContainer from "@pages/bookmarks/BookmarksContainer";
import ProfileContainer from "@pages/profile/ProfileContainer";
import GroupsContainer from "@/pages/groups/GroupsContainer";
import GroupDetailsContainer from "@/pages/groups/GroupDetailsContainer";
import GlossaryContainer from "@pages/legal/GlossaryContainer";
import ProfileEditContainer from "@pages/profile/edit/ProfileEditContainer";
import AccountEditContainer from "@pages/profile/edit/AccountEditContainer";
import ActivateContainer from "@pages/auth/ActivateContainer";
import RequestPasswordResetContainer from "@pages/auth/RequestPasswordResetContainer";
import ResetPasswordContainer from "@pages/auth/ResetPasswordContainer";
import ChangePasswordContainer from "@/pages/auth/ChangePasswordContainer";
import HashtagContainer from "@/pages/hashtags/HashtagContainer";
import DiscoverContainer from "@/pages/discover/DiscoverContainer";
import InfoContainer from "@/pages/legal/InfoContainer";
import MarketingContainer from "@/pages/legal/MarketingContainer";
import LogoutContainer from "@pages/auth/LogoutContainer";
import CreateJob from "@components/elements/jobs/form/CreateJob";
import JobWrapper from "@pages/jobs/JobWrapper";
import CookiesContainer from "@/pages/legal/CookiesContainer";
import AccountDeactivationPage from "@pages/account/AccountDeactivationPage";
import * as Sentry from "@sentry/react";
import CreatePost from "@components/elements/posts/form/CreatePost";
import CreateWatchContainer from "@pages/watch/CreateWatchContainer";
import { useAppSelector } from "@/state/hooks";
import { useMe } from "@/api/auth/me";
import useLoginModal from "@/state/modal/useLoginModal";
import isApp from "@/utils/isApp";
import WatchMobilePage from "@pages/watch/WatchMobilePage";
import MobileMainWrapper from "@pages/MobileMainWrapper";
import isMobile from "@/utils/isMobile";
import { NotificationContextProvider } from "@/contexts/NotificationContext";
import usePaymentModal from "@/state/modal/usePaymentModal";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const Router = () => {
  const { user, token } = useAppSelector((state) => state.user);
  // const me = useMe(token);
  const loginModal = useLoginModal();
  const paymentModal = usePaymentModal();
  const location = useLocation();

  useEffect(() => {
    // if (me.isLoading) return;
    if (user === null) {
      loginModal.open();
    } else {
      loginModal.close();
      if (!user.payment_active && !paymentModal.isOpen) {
        paymentModal.open();
      }
    }
  }, [user, location]);

  return (
    <SentryRoutes>
      {isApp && (
        <Route
          path="/watch/für_dich"
          index
          element={<WatchMobilePage tab="for_you" />}
        />
      )}
      {isMobile && (
        <Route path={"/messenger"} element={<MobileMainWrapper />}>
          <Route path="" index element={<MessengerContainer />} />
        </Route>
      )}
      <Route path="/" element={<MainWrapper />}>
        <Route path="/" element={<Navigate to="/news" />} />
        <Route path="/news" element={<Navigate to="/news/für_dich" />} />
        <Route
          path="/news/für_dich"
          index
          element={<NewsContainer filter="for_you" />}
        />
        <Route
          path="/news/mein_feed"
          index
          element={<NewsContainer filter="my_feed" />}
        />
        <Route
          path="/news/following"
          index
          element={<NewsContainer filter="following" />}
        />
        <Route
          path="/news/trending"
          index
          element={<NewsContainer filter="trending" />}
        />
        <Route path="/news/erstellen" index element={<CreatePost />} />
        <Route
          path="/news/create"
          index
          element={<Navigate to="/news/erstellen" replace />}
        />
        <Route path="/inserate" index element={<AssignmentsContainer />} />
        <Route
          path="/inserate/create"
          element={<Navigate to="/verwaltung/inserate/inserate_erstellen" />}
        />
        <Route
          path="/verwaltung/inserate/inserate_erstellen"
          index
          element={<AssignmentsContainer filterDefault="create" />}
        />
        <Route path="/inserate/:id" index element={<AssignmentsDetails />} />
        <Route
          path="/Job-Market/Stellenanzeigen"
          element={<Navigate to="/jobs" replace />}
        />
        <Route
          path="/jobs"
          element={<Navigate to="/jobs/für_dich" replace />}
        />
        <Route
          path="/jobs/für_dich"
          index
          element={<JobsContainer tab="all" />}
        />
        <Route
          path="/jobs/meine_jobs"
          element={<Navigate to="/jobs/meine_jobs/übersicht" />}
        />
        <Route
          path="/jobs/meine_jobs/übersicht"
          index
          element={<JobsContainer tab="my" />}
        />
        <Route
          path="/jobs/meine_jobs/nachrichten"
          index
          element={<JobsContainer tab="my" />}
        />
        <Route
          path="/jobs/meine_jobs/werdegänge"
          index
          element={<JobsContainer tab="my" />}
        />
        <Route
          path="/jobs/meine_jobs/statistik"
          index
          element={<JobsContainer tab="my" />}
        />
        <Route
          path="/jobs/jobs_erstellen"
          element={<Navigate to="/verwaltung/jobs/jobs_erstellen" replace />}
        />
        <Route
          path="/verwaltung/jobs/jobs_erstellen"
          index
          element={
            <JobWrapper>
              <CreateJob />
            </JobWrapper>
          }
        />
        <Route
          path="/jobs/follower"
          index
          element={<JobsContainer tab="follower" />}
        />
        <Route
          path="/jobs/trending"
          index
          element={<JobsContainer tab="trending" />}
        />
        <Route path="/jobs/:id" index element={<JobsDetails />} />
        <Route path="/login" index element={<LoginContainer />} />
        <Route path="/logout" index element={<LogoutContainer />} />
        <Route
          path="/verwaltung/passwort"
          index
          element={<RequestPasswordResetContainer />}
        />
        <Route
          path="/password_reset"
          index
          element={<ResetPasswordContainer />}
        />
        <Route
          path="/changepassword"
          index
          element={<ChangePasswordContainer />}
        />
        <Route
          path="/bookmarks"
          element={<Navigate to="/bookmarks/watch" replace />}
        />
        <Route
          path="/bookmarks/watch"
          index
          element={<BookmarksContainer tab="watch" />}
        />
        <Route
          path="/bookmarks/feed"
          index
          element={<BookmarksContainer tab="feed" />}
        />
        <Route
          path="/bookmarks/inserate"
          index
          element={<BookmarksContainer tab="assignments" />}
        />
        <Route
          path="/bookmarks/jobs"
          index
          element={<BookmarksContainer tab="jobs" />}
        />
        <Route
          path="/bookmarks/marketplace"
          index
          element={<BookmarksContainer tab="marketplace" />}
        />
        <Route
          path="/contacts"
          element={<Navigate to="/kontakte/für_dich" replace />}
        />
        <Route path="/kontakte" element={<ContactsContainer />}>
          <Route
            path=""
            element={<Navigate to="/kontakte/für_dich" replace />}
          />
        </Route>
        <Route
          path="/kontakte/für_dich"
          element={<Navigate to="/kontakte/für_dich/alle" />}
        />
        <Route
          path="/kontakte/für_dich/alle"
          index
          element={<ContactsContainer tab="for_you" subTab="all" />}
        />
        <Route
          path="/kontakte/für_dich/personen"
          index
          element={<ContactsContainer tab="for_you" subTab="person" />}
        />
        <Route
          path="/kontakte/für_dich/firmen"
          index
          element={<ContactsContainer tab="for_you" subTab="company" />}
        />
        <Route
          path="/kontakte/für_dich/vereine"
          index
          element={<ContactsContainer tab="for_you" subTab="club" />}
        />
        <Route
          path="/kontakte/meine_kontakte/übersicht"
          index
          element={<ContactsContainer tab="my_contacts" subTab="all" />}
        />
        <Route
          path="/kontakte/meine_kontakte/angefragt"
          index
          element={<ContactsContainer tab="my_contacts" subTab="sent" />}
        />
        <Route
          path="/kontakte/meine_kontakte/blocked"
          index
          element={<ContactsContainer tab="my_contacts" subTab="blocked" />}
        />
        <Route
          path="/kontakte/meine_kontakte/ausstehend"
          index
          element={<ContactsContainer tab="my_contacts" subTab="received" />}
        />
        <Route
          path="/kontakte/meine_kontakte/geburtstage"
          index
          element={<ContactsContainer tab="my_contacts" subTab="birthdays" />}
        />
        <Route
          path="/kontakte/meine_kontakte/statistik"
          index
          element={<ContactsContainer tab="my_contacts" subTab="stats" />}
        />
        <Route
          path="/kontakte/firmen"
          index
          element={<ContactsContainer subTab="company" />}
        />
        <Route
          path="/kontakte/birthdays"
          index
          element={
            <ContactsContainer tab="meine_kontakte" subTab="birthdays" />
          }
        />
        <Route
          path="/kontakte/follower"
          index
          element={<ContactsContainer tab="follower" />}
        />
        <Route
          path="/kontakte/following"
          index
          element={<ContactsContainer tab="following" />}
        />
        <Route
          path="/kontakte/trending"
          index
          element={<ContactsContainer tab="trending" />}
        />
        <Route
          path="/kontakte/:id/follower"
          element={<Navigate to="/kontakte/follower" replace />}
        />
        <Route
          path="/kontakte/:id/following"
          element={<Navigate to="/kontakte/following" replace />}
        />
        <Route
          path="/watch"
          element={<Navigate to="/watch/für_dich" replace />}
        />
        {!isApp && (
          <Route
            path="/watch/für_dich"
            index
            element={<WatchContainer tab="for_you" />}
          />
        )}
        <Route
          path="/watch/mein_watch"
          index
          element={<WatchContainer tab="my_watch" />}
        />
        <Route
          path="/watch/video_erstellen"
          element={<Navigate to="/watch/erstellen" replace />}
        />
        <Route
          path="/watch/create"
          element={<Navigate to="/watch/erstellen" replace />}
        />
        <Route
          path="/watch/erstellen"
          index
          element={<CreateWatchContainer />}
        />
        <Route
          path="/watch/following"
          index
          element={<WatchContainer tab="following" />}
        />
        <Route
          path="/watch/trending"
          index
          element={<WatchContainer tab="trending" />}
        />
        {!isMobile && (
          <Route path="/messenger" index element={<MessengerContainer />} />
        )}
        <Route
          path="/profile/edit"
          element={<Navigate to="/verwaltung/profile" replace />}
        />
        <Route
          path="/verwaltung/profile"
          index
          element={<ProfileEditContainer />}
        />
        <Route path="/profile/:userId" index element={<ProfileContainer />} />
        <Route
          path="/profile/:userId/über_uns"
          element={<ProfileContainer filter="about" />}
        />
        <Route
          path="/profile/:userId/über_mich"
          element={<ProfileContainer filter="about" />}
        />
        <Route
          path="/profile/:userId/about"
          element={<ProfileContainer filter="about" />}
        />
        <Route
          path="/profile/:userId/news"
          element={<ProfileContainer filter="news" />}
        />
        <Route
          path="/profile/:userId/feed"
          element={<ProfileContainer filter="news" />}
        />
        <Route
          path="/profile/:userId/watch"
          element={<ProfileContainer filter="watch" />}
        />
        <Route
          path="/profile/:userId/jobs"
          element={<ProfileContainer filter="jobs" />}
        />
        <Route
          path="/profile/:userId/shop"
          element={<ProfileContainer filter="ads" />}
        />
        <Route
          path="/profile/:userId/assignments"
          element={<Navigate to="/profile/:userId/inserate" replace />}
        />
        <Route
          path="/profile/:userId/inserate"
          element={<ProfileContainer filter="assignments" />}
        />
        <Route
          path="/marketplace"
          element={<Navigate to="/verwaltung/marketplace/für_dich" replace />}
        />
        <Route
          path="/verwaltung/marketplace"
          index
          element={<Navigate to="/verwaltung/marketplace/für_dich" />}
        />
        <Route
          path="/verwaltung/marketplace/für_dich"
          index
          element={<MarketplaceContainer mainFilter="main_marketplace" />}
        />
        <Route
          path="/verwaltung/mein_shop"
          element={<Navigate to="/verwaltung/mein_shop/übersicht" replace />}
        />
        <Route
          path="/verwaltung/mein_shop/übersicht"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="all"
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/messenger"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="messenger"
            />
          }
        />
        <Route
          path="/marketplace/stats"
          element={<Navigate to="/verwaltung/mein_shop/statistik" replace />}
        />
        <Route
          path="/verwaltung/mein_shop/statistik"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="stats"
              subTab=""
            />
          }
        />
        <Route
          path="/marketplace/create"
          element={<Navigate to="/verwaltung/mein_shop/verkaufen" replace />}
        />
        <Route
          path="/verwaltung/mein_shop/verkaufen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="create"
              subTab=""
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/verkäufe"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="sells"
              subTab=""
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/rechnungen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="bills"
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/vorlagen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="templates"
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/importieren"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="import"
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/rechnungen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="shop"
              subTab="bills"
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/following"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="following"
              subTab=""
            />
          }
        />
        <Route
          path="/marketplace/follower"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="follower"
              subTab=""
            />
          }
        />
        <Route
          path="/marketplace/trending"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="trending"
              subTab=""
            />
          }
        />
        <Route
          path="/marketplace/wishlist"
          element={
            <Navigate to="/verwaltung/marketplace/wunschliste" replace />
          }
        />
        <Route
          path="/verwaltung/marketplace/wunschliste"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="wishlist"
              subTab=""
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/kaufübersicht"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="shop"
              subTab="bought"
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/wieder_kaufen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="shop"
              subTab="rebuy"
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/zahlungsmethode"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="shop"
              subTab="paymethod"
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/zahlungsmethode"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="paymethod"
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/zuletzt_gesehen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="shop"
              subTab="last_viewed"
            />
          }
        />
        <Route
          path="/account/deaktivieren"
          index
          element={<AccountDeactivationPage />}
        />
        <Route
          path="/verwaltung/marketplace/bestellungen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="shop"
              subTab="orders"
            />
          }
        />
        <Route
          path="/verwaltung/mein_shop/bestellungen"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_my_shop"
              topTab="my_shop"
              subTab="orders"
            />
          }
        />
        <Route
          path="/verwaltung/marketplace/warenkorb"
          index
          element={
            <MarketplaceContainer
              mainFilter="main_marketplace"
              topTab="cart"
              subTab=""
            />
          }
        />
        <Route path="/marketplace/:id" index element={<MarketplaceDetails />} />
        <Route
          path="/tags/:hashtag"
          index
          element={<HashtagContainer tab="feed" />}
        />
        <Route
          path="/tags/:hashtag/watch"
          index
          element={<HashtagContainer tab="watch" />}
        />
        <Route
          path="/tags/:hashtag/feed"
          index
          element={<HashtagContainer tab="feed" />}
        />
        <Route path="/impressum" index element={<LegalNoticeContainer />} />
        <Route path="/info" index element={<InfoContainer />} />
        <Route path="/marketing" index element={<MarketingContainer />} />
        <Route path="/agb" index element={<ConditionsContainer />} />
        <Route
          path="/datenschutzrichtlinie"
          index
          element={<PrivacyContainer />}
        />
        <Route path="/conditions" index element={<ConditionsContainer />} />
        <Route path="/urheberrecht" index element={<CopyRightContainer />} />
        <Route
          path="/privacy"
          element={<Navigate to="/datenschutz" replace />}
        />
        <Route path="/datenschutz" index element={<PrivacyContainer />} />
        <Route path="/cookies" index element={<CookiesContainer />} />
        <Route
          path="/nutzungsbedingungen"
          index
          element={<TermsOfUseContainer />}
        />
        <Route path="/community" index element={<CommunityRulesContainer />} />
        <Route path="/help" index element={<HelpContainer />} />
        <Route path="/mehr" index element={<MoreContainer />} />
        <Route
          path="/industriegeschichte"
          index
          element={<IndustrialHistory />}
        />
        <Route
          path="/industriegeschichte/:id"
          index
          element={<IndustrialHistoryDetails />}
        />
        <Route path="/global_agieren" index element={<ActGlobal />} />
        <Route path="/account_erstellen" index element={<CreateAccount />} />
        <Route path="/startup_tipps" index element={<StartupAdvice />} />
        <Route path="/nachfolger" index element={<Successor />} />
        <Route path="/kunst_kultur" index element={<LeisureMaps />} />
        <Route path="/amts_gemeindeblätter" index element={<Newspapers />} />
        <Route path="/marken_patentrecht" index element={<Copyright />} />
        <Route path="/watch_faq" index element={<WatchFAQ />} />
        <Route path="/mitglieder_faq" index element={<MemberFAQ />} />
        <Route path="/firmen_faq" index element={<CompanyFAQ />} />
        <Route path="/spendenaktionen" index element={<Donations />} />
        <Route path="/newsletter" index element={<Newsletter />} />
        <Route path="/newsletter_abmelden" index element={<Unsubscripe />} />
        <Route path="/messe_kalender" index element={<EventCalendar />} />
        <Route path="/support" index element={<Support />} />
        <Route path="/partner" index element={<Partner />} />
        <Route path="/glossary" index element={<GlossaryContainer />} />
        <Route path="/activate" index element={<ActivateContainer />} />
        <Route
          path="/entdecken"
          element={<Navigate to="/entdecken/hashtags/feed" replace />}
        />
        <Route
          path="/entdecken/hashtags"
          element={<Navigate to="/entdecken/hashtags/feed" replace />}
        />
        <Route
          path="/entdecken/hashtags/feed"
          index
          element={<DiscoverContainer tab="hashtags" subTab="feed" />}
        />
        <Route
          path="/entdecken/hashtags/watch"
          index
          element={<DiscoverContainer tab="hashtags" subTab="watch" />}
        />
        <Route
          path="/entdecken/watch"
          index
          element={<DiscoverContainer tab="watch" />}
        />
        <Route
          path="/entdecken/news"
          index
          element={<DiscoverContainer tab="news" />}
        />
        <Route
          path="/entdecken/profile"
          index
          element={<DiscoverContainer tab="profiles" subTab="" />}
        />
        <Route
          path="/entdecken/profile/personen"
          index
          element={<DiscoverContainer tab="profiles" subTab="personen" />}
        />
        <Route
          path="/entdecken/profile/firmen"
          index
          element={<DiscoverContainer tab="profiles" subTab="firmen" />}
        />
        <Route
          path="/entdecken/profile/vereine"
          index
          element={<DiscoverContainer tab="profiles" subTab="vereine" />}
        />
        <Route
          path="/entdecken/profile/gruppen"
          index
          element={<DiscoverContainer tab="profiles" subTab="gruppen" />}
        />
        <Route
          path="/entdecken/jobs"
          index
          element={<DiscoverContainer tab="jobs" />}
        />
        <Route
          path="/entdecken/inserate"
          index
          element={<DiscoverContainer tab="assignments" />}
        />
        <Route
          path="/entdecken/marketplace"
          index
          element={<DiscoverContainer tab="ads" />}
        />
        <Route
          path="/gruppen/"
          element={<Navigate to="/gruppen/für_dich" replace />}
        />
        <Route
          path="/gruppen/für_dich"
          index
          element={<GroupsContainer tab="for_you" />}
        />
        <Route
          path="/gruppen/meine_gruppen"
          index
          element={<GroupsContainer tab="my_groups" />}
        />
        <Route
          path="/gruppen/create"
          element={<Navigate to="/gruppen/erstellen" replace />}
        />
        <Route
          path="/gruppen/erstellen"
          index
          element={<GroupsContainer tab="create" />}
        />
        <Route
          path="/gruppen/erstellen"
          index
          element={<GroupsContainer tab="create" />}
        />
        <Route
          path="/gruppen/following"
          index
          element={<GroupsContainer tab="following" />}
        />
        <Route
          path="/gruppen/trending"
          index
          element={<GroupsContainer tab="trending" />}
        />
        <Route
          path="/gruppen/:userId"
          index
          element={<GroupDetailsContainer />}
        />
        <Route
          path="/register"
          element={<Navigate to="/registrierung" replace />}
        />
        <Route path="/registrierung" index element={<RegisterContainer />} />
        <Route
          path="/Mein-Account/nachrichten"
          element={<Navigate to="/messenger" replace />}
        />
        <Route
          path="/Firmenverzeichnis"
          element={<Navigate to="/contacts/firmen" replace />}
        />
        <Route
          path="/Deine-Auftragsplattform"
          element={<Navigate to="/inserate" replace />}
        />
        <Route
          path="/Online-Market"
          element={<Navigate to="/marketplace" replace />}
        />
        <Route path="/Ueber-uns" element={<Navigate to="/info" replace />} />
        <Route
          path="/Tradefoox-Funktionen-So-geht`s"
          element={<Navigate to="/help" replace />}
        />
        <Route
          path="/Mediadaten-Tradefoox"
          element={<Navigate to="/marketing" replace />}
        />
        <Route path="*" element={<Navigate to="/news" replace={true} />} />
      </Route>
    </SentryRoutes>
  );
};

export default Router;
