import React from "react";
import { User } from "@/utils/user";
import { useMutation } from "@tanstack/react-query";
import api from "@/api/api";
import { Product } from "@/utils/product";
import { Cart } from "@/utils/cart";
import Button from "@/components/elements/input/Button";
import { useDispatch } from "react-redux";
import { addItem } from "@/state/cart/cartSlice";
import Alert from "@/components/elements/Alert";
import usePopupModal from "@/state/modal/usePopupModal";
import { AxiosError } from "axios";

type Props = {
  me: User;
  prod: Product;
  size?: number;
};

const AddCartButton = ({ prod, me }: Props) => {
  const dispatch = useDispatch();
  const popupModal = usePopupModal();

  const addCart = useMutation([`addcart-${me.id}-${prod.id}`], {
    mutationFn: async () => {
      const res = await api.post(`/api/v1/carts/${prod.id}`);
      return res.data;
    },
    onSuccess: () => {
      const newItem: Cart = {
        product_id: prod.id,
        title: prod.title,
        description: prod.description,
        price_net: prod.price_net,
        price_gross: prod.price_gross,
        currency: "USD",
        quantity: 1,
      };
      dispatch(addItem(newItem));
    },
    onError: (data: AxiosError) => {
      if (data.response?.status === 422)
        popupModal.open(
          <Alert
            buttons={Alert.ButtonVariants.OK}
            onClose={popupModal.close}
            message={"The request quantity surpasses product stock."}
            title="Add Cart Error"
          />,
        );
    },
  });

  return (
    <Button
      onClick={addCart.mutate}
      size={Button.Sizes.Small}
      variant={Button.Variants.Transparent}
    >
      Add Cart
    </Button>
  );
};

export default AddCartButton;
