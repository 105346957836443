export const SHOW_REPORT_MODAL = "SHOW_REPORT_MODAL" as const;
export const HIDE_REPORT_MODAL = "HIDE_REPORT_MODAL" as const;

export type ShowReportModalAction = {
  type: typeof SHOW_REPORT_MODAL;
  payload: {
    itemId: string;
    type: string;
    title?: string;
  };
};

export type HideReportModalAction = {
  type: typeof HIDE_REPORT_MODAL;
};

export type ReportModalActions = ShowReportModalAction | HideReportModalAction;
