import React from "react";
import { Product } from "@/utils/product";
import Button from "@/components/elements/input/Button";
import { useMutation } from "@tanstack/react-query";
import api from "@/api/api";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import AddCartButton from "@/components/elements/AddCartButton";
import { useAppSelector } from "@/state/hooks";

type Props = {
  product: Product;
  key: string;
}

const MarketplaceShopAllItem = ({
  product
}: Props) => {
  const popupModal = usePopupModal();
  const { user: userData } = useAppSelector((state) => state.user);
  const checkoutMutation = useMutation(["payment-checkout-session"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/payment/checkout-session", {
        product_id: product.id,
        quantity: 2,
      });
      return res.data;
    },
    onSuccess: (data) => {
      const checkout_link = data.checkout_link;
      setTimeout(() => window.location.assign(checkout_link), 200);
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Try again in a few minutes."
          title="Checkout"
        />,
      );
    },
  });

  return (
    <>
      <div className="bg-white rounded-xl border border-gray-300 round-lg">
        <a href="#">
          <div className="relative pb-[100%] w-full">
            <img src={product.image_url} alt="Product" className=" absolute h-full w-full object-cover rounded-t-xl" />
          </div>
          <div className="px-4 py-3">
            <span className="text-gray-400 uppercase text-xs">{product.brand}</span>
            <p className="text-base  text-black truncate block capitalize">{product.title}</p>
            <div className="flex items-center mb-2">
              <p className="text-lg font-semibold text-black cursor-auto">
                {(parseFloat(`${product.price_gross}`) / 100).toFixed(2)} €
              </p>
              <del>
                <p className="text-sm text-gray-600 cursor-auto ml-2 pb-1">
                  {(parseFloat(`${product.price_net}`) / 100).toFixed(2)} €
                </p>
              </del>
            </div>
            <div className="flex justify-between">
              <Button
                onClick={checkoutMutation.mutate}
                size={Button.Sizes.Small}
                variant={Button.Variants.Transparent}
              >
                Buy
              </Button>

              {userData &&
                <AddCartButton
                  me={userData}
                  prod={product}
                />
              }
            </div>
          </div>
        </a >
      </div >
    </>
  );
};

export default MarketplaceShopAllItem;