import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";
import ShoppingCartItem from "@/pages/marketplace/shop/ShoppingCartItem";
import { Cart } from "@/utils/cart";
import Button from "@/components/elements/input/Button";
import api from "@/api/api";
import { useMutation } from "@tanstack/react-query";
import usePopupModal from "@/state/modal/usePopupModal";
import Alert from "@/components/elements/Alert";
import { AxiosError } from "axios";

const ShoppingCartContainer = () => {
  const navigate = useNavigate();
  const popupModal = usePopupModal();
  const { cartContent } = useAppSelector((state) => state.cart);
  const [totalPrice, setTotalPrice] = React.useState(0);

  React.useEffect(() => {
    if (cartContent && cartContent.length !== 0) {
      setTotalPrice(
        cartContent
          .map(item => item.price_gross * item.quantity)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0));
    } else {
      setTotalPrice(0);
    }
  }, [cartContent]);

  const CartErrorPopup = (message: string) => {
    popupModal.open(
      <Alert
        buttons={Alert.ButtonVariants.OK}
        onClose={popupModal.close}
        message={message}
        title="Cart Error"
      />,
    );
  };

  const checkoutMutation = useMutation(["payment-checkout-session"], {
    mutationFn: async () => {
      const res = await api.post("/api/v1/payment/checkout-session", cartContent);
      return res.data;
    },
    onSuccess: (data) => {
      const checkout_link = data.checkout_link;
      setTimeout(() => window.location.assign(checkout_link), 200);
    },
    onError: (data: AxiosError) => {
      const status = data?.response?.status;
      const invalid_products = data.response?.data;
      if (status === 404)
        CartErrorPopup(`The products you requested are currently sold out.\n ${JSON.stringify(invalid_products)}`);
      if (status === 410)
        CartErrorPopup(`The products you requested are currently unavailable. Please explore our new products.\n ${JSON.stringify(invalid_products)}`);
    },
  });

  return (
    <>
      <div className="flex flex-col max-w-3xl">
        {cartContent.map((item: Cart, index: number) => <ShoppingCartItem item={item} key={index} />)}
        <div className="space-y-1 text-right">
          <p>Total amount:
            <span className="font-semibold">
              {(parseFloat(`${totalPrice}`) / 100).toFixed(2)} €
            </span>
          </p>
          <p className="text-sm dark:text-gray-400">Not including taxes and shipping costs</p>
        </div>
        <div className="flex justify-end space-x-4">
          <Button
            onClick={() => navigate("/marketplace")}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            Back to shop
          </Button>

          {cartContent && <Button
            onClick={checkoutMutation.mutate}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            Continue to Checkout
          </Button>}
        </div>
      </div>
    </>
  );
};

export default ShoppingCartContainer;